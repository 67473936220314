function getToday() {
	var now = new Date;
	var today = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
	return today;
}

export function hide_news_today() {
	localStorage.setItem('top_news_latest_day', getToday());
}

export function should_show_news_today() {
	return getToday() != localStorage.getItem('top_news_latest_day');
}
