/**
 * トップNEWマークの有無取得
 */

import ConfigurationBuilder from "./api/configuration_builder";
import { AjaxApi } from "~/plugins/openapi/api";

/**
 * トップNEWマークの有無取得するコマンド
 */
export class GetNoticeCommand {
  /**
   * @param {string} oshiraseId
   * @param {AjaxApi} $ajaxApi
   */
  constructor(
    $ajaxApi = new AjaxApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$ajaxApi = $ajaxApi;
  }

  /**
   * @returns {any}
   */
  execute() {
    try {
      return this.$ajaxApi.ajaxNoticePost().then(({ data }) => data);
    } catch (error) {
      console.error(error);
      return {};
    }
  }
}
