import { DialogType } from "..";
import BaseDialogCtrl from "../base/controller";

/**
 * お知らせダイアログ
 */
export default class InformationDialogCtrl extends BaseDialogCtrl {
  _type = DialogType.Information;

  constructor(display_checkbox = false) {
    super();

    this.display_checkbox = display_checkbox;
  }
}