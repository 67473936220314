import { GetNoticeCommand } from "~~/commands/notice";
function cf_footer_setBadge() {} // @todo DA06003-128 移植作業を進めるために一旦モックで対応しているので、後で修正する。user_policy/indexにも同様の処理あり

/**
 * バッジの表示制御
 * @param {function} $callback - コールバック関数
 * @returns {any}
 */
export async function getNotice(callback = () => {}) {
  const r = await new GetNoticeCommand().execute();

  if (r.redirect_uri) {
    if (r.redirect_uri == '/page/mainte') {
      if (location.pathname != r.redirect_uri + '/') {
        location.href = r.redirect_uri;
      }
    }
    else {
      location.href = "/login/";
    }
    return;
  }
  // if not  login
  if (parseInt(r.logout) == 1) {
    $("#header_left p").hide();

    $("#nav_me .notice_count").hide();
    $("#nav_co .notice_count").hide();
    $("#nav_rireki .notice_count").hide();
    return callback();
  }
  const totalNoticeCount = parseInt(r.total_count);
  if (totalNoticeCount == 0) {
    $(".menu").removeClass("is-badge");
    // 来場者限定ガチャチケットをゲット等のユーザー毎へのお知らせがある場合はmenuには付与しないが、タブにはN付与
    if (parseInt(r.user_notice_count) == 0) {
      cf_footer_setBadge(function () {}, 0, "0");
    } else {
      cf_footer_setBadge(function () {}, 0, "N");
    }
  } else {
    $(".menu").addClass("is-badge");
    cf_footer_setBadge(function () {}, 0, "N");
  }
  if (parseInt(r.unreaded_information_count) == 0) {
    $("#mainArea #btns1 .btn_info_area").removeClass("new");
  } else {
    $("#mainArea #btns1 .btn_info_area").addClass("new");
  }

  if (parseInt(r.message_count) == 0) {
    $("#mainArea .btn_trade_area").removeClass("new");
  } else {
    $("#mainArea .btn_trade_area").addClass("new");
  }

  if (parseInt(r.mission_count) == 0) {
    $("#mainArea #btns1 .btn_mission_area").removeClass("new");
  } else {
    $("#mainArea #btns1 .btn_mission_area").addClass("new");
  }
  if (parseInt(r.present_count) == 0) {
    $("#mainArea #btns1 .btn_present_area").removeClass("new");
    $("#mainArea #btns1 .btn_present_area > a").removeClass("new");
  } else {
    $("#mainArea #btns1 .btn_present_area").addClass("new");
    $("#mainArea #btns1 .btn_present_area").addClass("new");
  }
  return callback();
}
